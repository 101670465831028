<template>
  <ion-page>
    <!-- header -->
    <ion-header>
      <ion-toolbar>
        <ion-grid>
          <ion-row class="align-center" id="row">
            <ion-col size="9" class="d-flex">
              <ion-buttons class="mr-2" @click="$emit('close-modal')">
                <ion-icon size="small" :icon="arrowBackOutline"></ion-icon>
              </ion-buttons>
              <ion-label class="fs-4 fw-bold">{{ $t('checkout') }}</ion-label>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-toolbar>
    </ion-header>

    <ion-content v-if="isDisplay">
      <ion-refresher slot="fixed" @ionRefresh="handleRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <ion-card v-if="!isOnline" class="mx-0 mt-0 px-3 py-3 shadow border-rad-0">
        <ion-card-content class="pa-0">
          <div class="text-center">
            <ion-text class="fs-2">{{ $t('page_taking_longer') }}</ion-text>
            <div class="mt-4 d-flex column-center">
              <ion-label class="fw-600 mb-2">{{ $t('pull_to_refresh') }}</ion-label>
              <ion-icon color="primary fs-2rem" :icon="arrowDownOutline"></ion-icon>
            </div>
          </div>
        </ion-card-content>
      </ion-card>
      <div class="bg-grey1 w-100" v-if="isOnline">
        <div class="pa-3 bg-white">
          <div class="d-flex border-bottom w-100 py-3">
            <ion-icon class="fs-4" :icon="businessOutline" />
            <ion-row class="adjust-top">
              <ion-col size="12">
                <span class="ml-2 fs-2 fw-500">{{
                  selectedCompany?.name ? selectedCompany?.name : selectedCompany?.branch
                }}</span>
              </ion-col>
              <ion-col size="12">
                <span class="ml-2 text-primary fs-2 fw-500">{{ selectedCompany?.account_number }}</span>
              </ion-col>
              <ion-col size="12" v-if="selectedCompany?.black_list">
                <div class="box-label d-flex align-center ml-2">
                  <ion-icon size="small" :icon="banOutline" color="danger"></ion-icon>
                  <span class="text-danger ml-1 fs-2 fw-500">{{ $t('black_listed') }}</span>
                </div>
              </ion-col>
            </ion-row>
          </div>

          <div class="py-3 border-bottom">
            <DeliveryInstructionArea
              @deliveryInstruction="getDeliveryInstruction"
              :instruction="customerView[0].delivery_instruction"
              :idModal="'addAndEdit'"
            />
          </div>
          <!-- delivery_address -->
          <div class="py-3 border-bottom">
            <div class="d-flex justify-space-between">
              <div>
                <ion-label color="grey" class="text-uppercase">{{ $t('delivery_address') }}</ion-label>
              </div>
              <div
                v-if="list.length <= 1 || isPickingDeliveryAndBilling.isSelectDelivery"
                @click="openModalAddress(TYPE_ADDRESS.DELIVERY)"
              >
                <ion-label color="primary" class="fw-500"> {{ $t('change') }}</ion-label>
              </div>
            </div>
            <div v-if="list.length <= 1 || isPickingDeliveryAndBilling.isSelectDelivery" class="mt-3">
              {{ addressDefault(selectedDeliveryAddress) }}
            </div>
            <ion-button
              v-if="list.length > 1 && !isPickingDeliveryAndBilling.isSelectDelivery"
              @click="openModalAddress(TYPE_ADDRESS.DELIVERY)"
              expand="block"
              fill="outline"
              >{{ $t('select_address') }}</ion-button
            >
          </div>
          <!-- billing_address -->
          <div class="py-3 border-bottom">
            <div class="d-flex justify-space-between">
              <div>
                <ion-label color="grey" class="text-uppercase">{{ $t('billing_address') }}</ion-label>
              </div>
              <div
                v-if="isPreSelectedAddress || isPickingDeliveryAndBilling.isSelectBilling"
                @click="openModalAddress(TYPE_ADDRESS.BILLING)"
              >
                <ion-label color="primary" class="fw-500"> {{ $t('change') }}</ion-label>
              </div>
            </div>
            <div v-if="isPreSelectedAddress || isPickingDeliveryAndBilling.isSelectBilling" class="mt-3">
              {{ addressDefault(selectedBillingAddress) }}
            </div>
            <ion-button
              v-if="isMustSelectAddress && !isPickingDeliveryAndBilling.isSelectBilling"
              @click="openModalAddress(TYPE_ADDRESS.BILLING)"
              expand="block"
              fill="outline"
              >{{ $t('select_address') }}</ion-button
            >
          </div>
        </div>

        <!-- tenant -->
        <div
          class="mt-3 bg-white pa-3"
          v-for="(tenantItems, index) in groupedTenant"
          :key="index + uniqueId()"
        >
          <!-- tenant name -->
          <div class="d-flex align-center pb-2 border-bottom">
            <ion-icon :icon="storefrontOutline" class="fs-4"></ion-icon>
            <ion-label class="ml-2 text-ellipsis">{{ tenantItems[0].tenant_name }}</ion-label>
          </div>

          <!-- delivery time -->
          <div class="py-3">
            <div class="d-flex justify-space-between">
              <div>
                <ion-label color="grey">{{ $t('delivery_time') }} </ion-label>
              </div>
              <div @click="chooseTime(index)">
                <ion-label color="primary" class="fw-500"> {{ $t('set_time') }}</ion-label>
              </div>
            </div>
            <div class="mt-3" v-if="deliveryDateNearest !== ''">
              {{ displayDeliveryDateNearest }}
              {{ data.cartItems[index].deliveryTime || '' }}
            </div>
            <div class="mt-3">
              {{ isShowNextDay ? '' : deliveryDateNearest ? $t('next_day') : $t('no_delivery_date') }}
            </div>
          </div>

          <!-- po number -->
          <ion-item class="fs-2 mb-3">
            <ion-label position="floating"> {{ $t('po_number') }} ({{ $t('optional') }})</ion-label>
            <ion-input tabindex="-1" v-model="data.cartItems[index].poNumber"></ion-input>
          </ion-item>

          <!-- product items -->
          <div class="border-bottom mb-4">
            <product-item-checkout
              v-for="item in tenantItems"
              :key="item.id"
              :item="item"
            ></product-item-checkout>
          </div>

          <!-- credit card -->
          <div v-if="!isSubBuyer" class="payment-type mt-4" @click="openModalCreditCard(index)">
            <div class="credit-card">
              <div class="d-flex align-center" v-if="!data.cartItems[index].paymentMethod">
                <ion-icon class="fs-4 mr-2" :icon="cashOutline"></ion-icon>
                <ion-label>
                  {{ $t('credit_term') }}
                  ({{ `${customerView[index]?.customer_credit_term.displayName}` }})
                </ion-label>
              </div>
              <div class="d-flex align-center" v-else-if="data.cartItems[index].paymentMethod === 'fluid'">
                <div class="mr-3 image-card d-flex">
                  <ion-img :src="FLUID_ICON" />
                </div>
                <div>
                  <ion-label>
                    {{ $t('fluid') }}
                  </ion-label>
                </div>
              </div>
              <div v-else class="d-flex align-center">
                <div class="mr-3 image-card d-flex">
                  <ion-img :src="getCardImage(listCards[index].brand)" />
                </div>
                <div>{{ $t('card') }} ({{ listCards[index].last4 }})</div>
              </div>
              <div class="text-end d-flex align-center">
                <ion-icon class="fs-4" :icon="chevronForwardOutline"></ion-icon>
              </div>
            </div>
          </div>

          <!-- order remark -->
          <ion-item class="fs-2">
            <ion-label position="floating"> {{ $t('note_to_driver') }} ({{ $t('optional') }})</ion-label>
            <ion-input
              tabindex="-1"
              v-model="data.cartItems[index].description"
              :placeholder="$t('add_delivery_instructions_or_directions')"
            ></ion-input>
          </ion-item>

          <!-- subtotal -->
          <div class="my-3 border-bottom pb-3 d-flex justify-space-between">
            <div>
              <ion-label color="dark">{{ $t('subtotal') }}</ion-label>
            </div>
            <div>
              <ion-label color="primary" class="fw-bold">
                {{ priceFormatter(currencySymbol, calculatingSubtotal(tenantItems)) }}
              </ion-label>
            </div>
          </div>

          <!-- total -->
          <div class="my-3 d-flex justify-space-between">
            <div>
              <ion-label color="dark">{{ $t('total') }}</ion-label>
            </div>
            <div>
              <ion-label color="primary" class="fw-bold">
                {{ priceFormatter(currencySymbol, calculatingSubtotal(tenantItems)) }}
              </ion-label>
            </div>
          </div>
        </div>
        <!-- subtotal -->
        <div class="pa-3 bg-white mt-3">
          <div class="my-3 pb-3 border-bottom d-flex justify-space-between">
            <div>
              <ion-label color="dark">{{ $t('subtotal') }}</ion-label>
            </div>
            <div>
              <ion-label color="primary" class="fw-bold">
                {{ priceFormatter(currencySymbol, displayPrice(grandSubtotal())) }}
              </ion-label>
            </div>
          </div>

          <!-- tax -->
          <div class="mt-3 d-flex justify-space-between">
            <div>
              <ion-label color="dark">{{ $t('tax') }}</ion-label>
            </div>
            <div>
              <ion-label color="primary" class="fw-bold">
                {{ priceFormatter(currencySymbol, displayPrice(grandTax())) }}
              </ion-label>
            </div>
          </div>
        </div>

        <!-- end -->
        <div class="pt-3"></div>
      </div>
    </ion-content>

    <ion-footer v-if="isDisplay && isOnline">
      <ion-toolbar>
        <div class="d-flex align-center justify-space-between pa-3">
          <div>
            <div>
              <ion-label color="dark">{{ $t('total_bill') }}</ion-label>
            </div>
            <div class="mt-2">
              <ion-label color="primary" class="fw-bold fs-3">
                {{ priceFormatter(currencySymbol, displayPrice(grandTotal())) }}
              </ion-label>
            </div>
          </div>
          <div>
            <ion-button
              class="ma-0 place-order"
              color="primary"
              :disabled="disablePlaceOrder"
              @click="placeOrder"
              >{{ $t('place_order') }}</ion-button
            >
          </div>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
  <ion-loading
    :is-open="isOpenRef"
    cssClass="custom-loading"
    message=""
    spinner="crescent"
    @didDismiss="setOpen(false)"
  >
  </ion-loading>
  <!-- modal address -->
  <ion-modal :is-open="isOpenAddressRef" css-class="modal-address" @didDismiss="setOpenAddress(false)">
    <modal-address
      :isDelivery="typeAddress === TYPE_ADDRESS.DELIVERY"
      :selectedDeliveryAddress="selectedDeliveryAddress"
      :selectedBillingAddress="selectedBillingAddress"
      @close-modal="setOpenAddress(false)"
      @update-infor="updateInfor"
    ></modal-address>
  </ion-modal>
  <!-- modal time -->
  <ion-modal :is-open="isOpenTimeRef" css-class="modal-time" @didDismiss="setOpenTime(false)">
    <modal-delivery-time
      :deliveryDates="dates"
      :deliveryDate="deliveryDateNearest"
      :deliveryTime="currentDateTime.data['deliveryTime']"
      @confirm="confirmDateTime"
      :checkIsTimeSlotAvailable="isTimeSlotAvailable"
      @close-modal="setOpenTime(false)"
    ></modal-delivery-time>
  </ion-modal>

  <!-- modal credit card -->
  <ion-modal
    :is-open="isOpenCreditCardRef"
    css-class="modal-credit-card"
    @didDismiss="setOpenCreditCard(false)"
  >
    <modal-credit-card
      :customerView="currentCustomerView.data"
      :isShowFluidPayment="isFluidCustomer"
      @choose-card="chooseCard"
      @close-modal="setOpenCreditCard(false)"
    ></modal-credit-card>
  </ion-modal>

  <!-- modal order confirm -->
  <ion-modal
    :is-open="isOpenOrderConfirmRef"
    css-class="modal-confirm"
    @didDismiss="setOpenOrderConfirm(false)"
    :backdropDismiss="false"
  >
    <modal-order-confirm
      :title="titleOrderConfirm"
      :subTitle="subTitleOrderConfirm"
      @on-yes="addOrder"
      @on-no="setOpenOrderConfirm(false)"
    ></modal-order-confirm>
  </ion-modal>
  <!-- modal blacklist feature -->
  <ModalBlacklist
    v-if="isOpenModalBlackList"
    :key="renderBlacklist"
    @on-no="setOpenModalBlackList(false)"
    :message="messageBlackList"
  />
  <!-- modal-cuisines-preferences -->
  <ion-modal :is-open="isOpenModalCuisine" :backdropDismiss="false">
    <CuisinePreferences
      :customerId="selectedCompany.id"
      :countryId="user.country.id"
      :isNewCompany="false"
      @later="handleCloasingCuisine()"
      @apply="handleCloasingCuisine()"
    />
  </ion-modal>
  <!-- modal-cuisines-preferences -->
  <!-- modal notification check duplicate order -->
  <modal-check-duplicate-order
    :key="reRender"
    v-if="isOpenCheckDuplicatieOrder"
    :title="titleCheckDuplicatieOrder"
    :subTitle="subTitleCheckDuplicatieOrder"
    :countModalCheckDuplicate="countModalCheckDuplicate"
    @on-yes="openCheckDuplicatieOrder"
    @on-no="skipCheckDuplicatieOrder"
  ></modal-check-duplicate-order>

  <modal-check-double-order
    :key="reRender"
    v-if="isOpenCheckDoubleOrder"
    :title="titleCheckDuplicatieOrder"
    :subTitle="subTitleCheckDuplicatieOrder"
    :countModalCheckDuplicate="countModalCheckDuplicate"
    @on-yes="proceesDoubleOrder"
  ></modal-check-double-order>
</template>
<script>
import { apolloClient } from '@/main';
import { CheckCustomerCuisines, getAvailableDeliverySlots } from '@/modules/b2b/services/graphql';
import DeliveryInstructionArea from '@/modules/sale/components/AddAndEditDeliveryInstruction/DeliveryInstructionArea.vue';
import ModalAddress from '@/modules/sale/components/ModalAddress.vue';
import ModalBlacklist from '@/modules/sale/components/ModalBlacklist.vue';
import ModalCheckDoubleOrder from '@/modules/sale/components/ModalCheckDoubleOrder.vue';
import ModalCheckDuplicateOrder from '@/modules/sale/components/ModalCheckDuplicateOrder.vue';
import ModalCreditCard from '@/modules/sale/components/ModalCreditCard.vue';
import ModalDeliveryTime from '@/modules/sale/components/ModalDeliveryTime.vue';
import ModalOrderConfirm from '@/modules/sale/components/ModalOrderConfirm.vue';
import {
  DEFAULT_ORDER,
  FLUID_ICON,
  PAYMENT_METHOD,
  SUB_BUYER_ID,
  TYPE_ADDRESS
} from '@/modules/sale/constants';
import { getFluidCustomerInfo, saleCheckBlackListOrder } from '@/modules/sale/services/graphql';
import { displayPrice, getAddressFormat, randomId } from '@/modules/sale/services/libs/helper';
import { stripe } from '@/modules/sale/services/libs/payment';
import { ACTIONS as ACTIONS_ADDRESS } from '@/modules/sale/store/address/actions';
import { ACTIONS as ACTIONS_CART } from '@/modules/sale/store/cart/actions';
import { ACTIONS as ACTIONS_CUSTOMER } from '@/modules/sale/store/customer/actions';
import { ACTIONS as ACTIONS_ORDER } from '@/modules/sale/store/order/actions';
import { ACTIONS as ACTIONS_PAYMENT } from '@/modules/sale/store/payment/actions';
import { ACTIONS as ACTIONS_PRODUCT } from '@/modules/sale/store/product/actions';
import { useLoading } from '@/modules/shared/utils/';
import { alertController } from '@ionic/vue';
import dayjs from 'dayjs';
import {
  arrowBackOutline,
  arrowDownOutline,
  banOutline,
  businessOutline,
  cardOutline,
  cashOutline,
  chevronForwardOutline,
  helpCircleOutline,
  storefrontOutline
} from 'ionicons/icons';
import { defineAsyncComponent, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { createNamespacedHelpers } from 'vuex';
import ProductItemCheckout from '../ProductItemCheckout.vue';
import { getCartItems } from './functions';
const { mapGetters: mapGettersCart, mapActions: mapActionsCart } = createNamespacedHelpers('sale/cart');
const { mapGetters: mapGettersAddress, mapActions: mapActionsAddress } = createNamespacedHelpers(
  'sale/address'
);
const { mapGetters: mapGettersCustomer, mapActions: mapActionsCustomer } = createNamespacedHelpers(
  'sale/customer'
);
const { mapGetters: mapGettersOrder, mapActions: mapActionsOrder } = createNamespacedHelpers('sale/order');
const { mapActions: mapActionsProduct } = createNamespacedHelpers('sale/product');
const { mapActions: mapActionsPayment, mapGetters: mapGettersPayment } = createNamespacedHelpers(
  'sale/payment'
);
// import { DATE_RELEASE_CUISINE_MERCHANT_APP } from '@/modules/shared/constants/common.js';
import Clevertap from '@/services/shared/helper/clevertap';
import { priceFormatter } from '@/utils/';
import { calDueDate } from '@/utils/datetime';

export default defineComponent({
  props: {
    itemsSelected: {
      type: Object || Array,
      required: true
    },
    cartData: {
      type: Object || Array,
      required: true
    }
  },

  components: {
    // components
    ModalBlacklist,
    ModalAddress,
    ProductItemCheckout,
    ModalDeliveryTime,
    ModalCreditCard,
    ModalOrderConfirm,
    ModalCheckDuplicateOrder,
    DeliveryInstructionArea,
    CuisinePreferences: defineAsyncComponent(() =>
      import('@/modules/shared/views/cuisine-preferences/index.vue')
    ),
    ModalCheckDoubleOrder
  },

  emits: ['close-modal', 'place-order', 'refresh-cart'],

  setup(props) {
    const user = ref(null);
    const selectedCompany = ref(null);
    //modal check notification duplicate order
    const isOpenCheckDuplicatieOrder = ref(false);
    const setOpenCheckDuplicatieOrder = (state) => (isOpenCheckDuplicatieOrder.value = state);
    //modal check notification double order
    const isOpenCheckDoubleOrder = ref(false);
    const setOpenCheckDoubleOrder = (state) => (isOpenCheckDoubleOrder.value = state);
    // modal address
    const isOpenAddressRef = ref(false);
    const setOpenAddress = (state) => (isOpenAddressRef.value = state);
    // modal order confirm
    const isOpenOrderConfirmRef = ref(false);
    const setOpenOrderConfirm = (state) => (isOpenOrderConfirmRef.value = state);
    // modal time
    const isOpenTimeRef = ref(false);
    const setOpenTime = (state) => (isOpenTimeRef.value = state);
    // modal add favorite
    const isOpenAddFavoriteRef = ref(false);
    const setOpenAddFavorite = (state) => (isOpenAddFavoriteRef.value = state);
    // modal credit card
    const isOpenCreditCardRef = ref(false);
    const setOpenCreditCard = (state) => (isOpenCreditCardRef.value = state);
    //modal black list feature
    const isOpenModalBlackList = ref(false);
    const setOpenModalBlackList = (state) => (isOpenModalBlackList.value = state);
    // loading
    const isOpenRef = ref(false);
    const setOpen = (state) => (isOpenRef.value = state);
    const { showLoading } = useLoading();
    const messageDuplicate = ref('');

    const currentDateTime = ref({
      index: 0,
      data: {
        deliveryDate: '',
        deliveryTime: ''
      }
    });
    const currentCustomerView = ref({
      index: 0,
      data: null
    });
    const uniqueId = () => randomId();
    const typeAddress = ref('');
    const selectedDeliveryAddress = ref(null);
    const selectedBillingAddress = ref(null);
    const setSelectedDeliveryAddress = (state) => (selectedDeliveryAddress.value = state);
    const setSelectedBillingAddress = (state) => (selectedBillingAddress.value = state);
    const tenantIds = ref([]);
    const data = ref(DEFAULT_ORDER);
    const listCards = ref([]);
    const dataFavoriteSkuIds = ref(null);
    const titleOrderConfirm = ref('');
    const subTitleOrderConfirm = ref('');
    const titleCheckDuplicateOrder = ref('');
    const subTitleCheckDuplicateOrder = ref('');
    const groupedTenant = ref([]);

    const getCartItemsData = getCartItems(props.itemsSelected);
    data.value.cartItems = getCartItemsData.cartItems;
    tenantIds.value = getCartItemsData.tenantIds;
    listCards.value = getCartItemsData.listCards;
    const listSkuIds = ref([]);
    const isDisplay = ref(false);
    const messageBlackList = ref(null);
    const dates = ref([]);
    const tomorrowDay = ref('');
    const deliveryDateNearest = ref('');
    const renderBlacklist = ref(1);
    const creditCard = ref(null);
    const reRender = ref(1);
    const isOpenModalCuisine = ref(false);
    const setOpenModalCuisine = (state) => (isOpenModalCuisine.value = state);
    const countModalCheckDuplicate = ref(null);
    const isPickingDeliveryAndBilling = ref({
      isSelectDelivery: false,
      isSelectBilling: false
    });
    const setIsPickingDeliveryAndBilling = (isSelectDelivery, isSelectBilling) => {
      isPickingDeliveryAndBilling.value.isSelectDelivery = isSelectDelivery;
      isPickingDeliveryAndBilling.value.isSelectBilling = isSelectBilling;
    };
    return {
      isPickingDeliveryAndBilling,
      setIsPickingDeliveryAndBilling,
      user,
      selectedCompany,
      isOpenCheckDuplicatieOrder,
      setOpenCheckDuplicatieOrder,
      isOpenCheckDoubleOrder,
      setOpenCheckDoubleOrder,
      isOpenAddressRef,
      setOpenAddress,
      typeAddress,
      TYPE_ADDRESS,
      showLoading,
      selectedDeliveryAddress,
      selectedBillingAddress,
      setSelectedDeliveryAddress,
      setSelectedBillingAddress,
      data,
      displayPrice,
      isOpenTimeRef,
      setOpenTime,
      currentDateTime,
      uniqueId,
      tenantIds,
      getCartItems,
      isOpenCreditCardRef,
      setOpenCreditCard,
      currentCustomerView,
      listCards,
      setOpenAddFavorite,
      isOpenAddFavoriteRef,
      dataFavoriteSkuIds,
      listSkuIds,
      setOpenOrderConfirm,
      isOpenOrderConfirmRef,
      titleOrderConfirm,
      subTitleOrderConfirm,
      priceFormatter,
      titleCheckDuplicateOrder,
      subTitleCheckDuplicateOrder,
      groupedTenant,
      isOpenModalBlackList,
      setOpenModalBlackList,
      messageBlackList,
      creditCard,
      reRender,
      isDisplay,
      dates,
      tomorrowDay,
      deliveryDateNearest,
      dayjs,
      renderBlacklist,
      messageDuplicate,
      // icons
      arrowBackOutline,
      businessOutline,
      storefrontOutline,
      arrowDownOutline,
      cardOutline,
      chevronForwardOutline,
      cashOutline,
      isOpenRef,
      setOpen,
      isOpenModalCuisine,
      setOpenModalCuisine,
      countModalCheckDuplicate,
      helpCircleOutline,
      banOutline,
      calDueDate,
      FLUID_ICON,
      deliverySlots: [],
      isOnline: ref(true)
    };
  },

  inject: ['$storage'],
  data() {
    return {
      deliveryInstruction: '',
      savedDeliveryInstruction: false,
      t: useI18n(),
      isFluidCustomer: false
    };
  },
  unmounted() {
    this.setIsPickingDeliveryAndBilling(false, false);
  },
  async mounted() {
    this.groupedTenant = this.itemsSelected;
    await this.showLoading(() =>
      Promise.allSettled([this.$storage.getUser(), this.$storage.getSelectedCompany()])
        .then((values) => {
          this.user = values[0].value;
          this.selectedCompany = values[1].value;
        })
        .then(() =>
          Promise.allSettled([
            this[ACTIONS_ORDER.GET_DELIVERY_TIMES](this.user.tenant.id),
            this[ACTIONS_PAYMENT.SALE_LIST_STRIPE_CARD](this.selectedCompany.id),
            this[ACTIONS_ADDRESS.GET_ADDRESSES]({
              supplierId: this.selectedCompany.id,
              active: true
            }),
            this[ACTIONS_CUSTOMER.GET_CUSTOMER_VIEW]({
              id: this.selectedCompany.id,
              tenantIds: this.tenantIds
            }),
            // check fluid customer
            this.handleCheckFluidCustomer()
          ]).then((values) => {
            this.deliveryTimes = values[0].value;

            return Promise.allSettled([
              this.handleGetDeliveryDate(),
              this.checkCardDefault(),
              this.getAddressDefault()
              // this.checkDeliveryTime(getTomorrow())
            ]);
          })
        )
    );

    this.data = {
      ...this.data,
      customerId: this.selectedCompany.id,
      deliveryAddressId: this.selectedDeliveryAddress,
      billingAddressId: this.selectedBillingAddress
    };
    this.handleGetTomorrowDay();
    this.setDefaultPaymentMethod();
    this.isDisplay = true;
  },

  computed: {
    ...mapGettersAddress(['list']),
    ...mapGettersCart(['cart', 'status', 'checkDuplicateOrder']),
    ...mapGettersCustomer(['customerView', 'customerDetails']),
    ...mapGettersOrder(['message']),
    ...mapGettersPayment(['saleListStripeCard']),
    addressDefault() {
      return (id) => {
        if (!id || !this.list) return;
        const index = this.list.findIndex((item) => item.id === id);
        return index !== -1 ? getAddressFormat(this.list[index]) : '';
      };
    },
    isPreSelectedAddress() {
      return this.list.filter((item) => item.is_default === true).length === 1 || this.list.length === 1;
    },
    isMustSelectAddress() {
      return this.list.length < 1 || !this.isPreSelectedAddress;
    },
    cartList() {
      return this.cartData.items.filter((item) => item.selected === true);
    },
    isSubBuyer() {
      return this.user && this.user.user_type_id === SUB_BUYER_ID;
    },
    currencySymbol() {
      return this.user && this.user.country.currency_symbol;
    },
    // showDeliveryDate() {
    //   return (index) => this.formatDeliveryDate(this.data.cartItems[index].deliveryDate);
    // },
    selectedItems() {
      return Object.values(this.groupedTenant)
        .flat()
        .filter((item) => item.selected);
    },
    availableItems() {
      return this.selectedItems.filter((item) => item.available);
    },
    disablePlaceOrder() {
      return (
        !this.data.deliveryAddressId ||
        !this.data.billingAddressId ||
        !this.deliveryDateNearest ||
        this.hasUpdateAddress
      );
    },
    displayDeliveryDateNearest() {
      if (dayjs().format('YYYY-MM-DD') === this.deliveryDateNearest) return this.$t('today');
      return this.tomorrowDay === this.deliveryDateNearest
        ? this.$t('tomorrow') + ', ' + dayjs(this.deliveryDateNearest).format('DD MMM YYYY')
        : dayjs(this.deliveryDateNearest).format('ddd, D MMM YYYY');
    },
    isShowNextDay() {
      let key = this.dates?.findIndex((date) => date.data === this.tomorrowDay);
      return !!this.dates?.[key]?.isAvailable;
    },
    // block place order btn if user hasn't selected delivery and billing address
    hasUpdateAddress() {
      if (this.list.length > 1) {
        return (
          !this.isPickingDeliveryAndBilling.isSelectDelivery ||
          !this.isPickingDeliveryAndBilling.isSelectBilling
        );
      } else {
        return false;
      }
    }
  },

  // methods
  methods: {
    ...mapActionsAddress([ACTIONS_ADDRESS.GET_ADDRESSES]),
    ...mapActionsCart([
      ACTIONS_CART.GET_CART_ITEMS,
      ACTIONS_CART.CHECK_CLOSING_TIME,
      ACTIONS_CART.CHECK_DUPLICATE_ORDER,
      ACTIONS_CART.FINISH_ORDER
    ]),
    ...mapActionsOrder([
      ACTIONS_ORDER.ADD_ORDER,
      ACTIONS_ORDER.CHECK_DOUBLE_ORDER,
      ACTIONS_ORDER.GET_DELIVERY_TIMES
    ]),
    ...mapActionsCustomer([ACTIONS_CUSTOMER.GET_CUSTOMER_VIEW, ACTIONS_CUSTOMER.GET_CUSTOMER_DETAILS]),
    ...mapActionsProduct([ACTIONS_PRODUCT.ADD_FAVORITE, ACTIONS_PRODUCT.CHECK_FAVORITE_SKUS]),
    ...mapActionsPayment([ACTIONS_PAYMENT.SALE_LIST_STRIPE_CARD]),
    handleGetTomorrowDay() {
      let currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 1);
      this.tomorrowDay = dayjs(new Date(currentDate)).format('YYYY-MM-DD');
    },
    async handleRefresh(event) {
      await this.detectConnection();
      event.target.complete();
    },
    setDefaultPaymentMethod() {
      const index = this.currentCustomerView.index;
      this.listCards[index]?.id && (this.data.cartItems[index].paymentMethod = 'stripe');
    },
    async handleCheckFluidCustomer() {
      const { data } = await apolloClient.query({
        query: getFluidCustomerInfo,
        variables: {
          tenantId: this.user.tenant.id,
          customerId: this.selectedCompany.id
        }
      });

      this.isFluidCustomer = !!data.getFluidCustomerInfo;
      const index = this.currentCustomerView.index;
      this.isFluidCustomer && (this.data.cartItems[index].paymentMethod = 'fluid');
    },
    async handleGetDeliveryDate() {
      let items = [];
      for (const value of this.groupedTenant[0]) {
        items.push({
          quantity: value.quantity,
          sku_id: value.sku_id
        });
      }

      const currentTime = new Date();
      const { data } = await apolloClient.query({
        query: getAvailableDeliverySlots,
        variables: {
          userActionDatetime: dayjs(currentTime).format('YYYY-MM-DDTHH:mm:ssZ'),
          items: items,
          tenantId: this.user.tenant.id,
          daysLimit: 30,
          isNewOrder: true
        }
      });

      this.deliverySlots = data.getAvailableDeliverySlots;

      let deliveryDateNearest = '';

      outerLoop: for (const ds of this.deliverySlots) {
        for (const slot of ds.slots) {
          if (slot.isAvailable) {
            this.data.cartItems.forEach((element) => {
              if (element.deliveryTime == '') {
                element.deliveryTime = slot.label;
              }
            });
            deliveryDateNearest = ds.date;
            break outerLoop;
          }
        }
      }

      const startDate = new Date();
      const endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + 30);

      const dates = this.deliverySlots.map((ds) => ({ data: ds.date, isAvailable: ds.isAvailable }));

      if (deliveryDateNearest.length === 0) deliveryDateNearest = this.tomorrowDay;
      for (let i in this.data.cartItems) {
        this.data.cartItems[i].deliveryDate = `${deliveryDateNearest}`;
      }
      this.deliveryDateNearest = deliveryDateNearest;
      this.dates = dates;
    },
    isTimeSlotAvailable(date, deliveryTime) {
      let isAvailable = false;

      for (const ds of this.deliverySlots) {
        if (ds.date === date) {
          for (const slot of ds.slots) {
            if (slot.label === deliveryTime) {
              isAvailable = slot.isAvailable;
              break;
            }
          }
        }
      }

      return isAvailable;
    },
    checkCardDefault() {
      try {
        if (!this.isFluidCustomer && this.saleListStripeCard?.data?.length !== 0) {
          this.listCards[0] = this.saleListStripeCard.data[0];
          this.data.cartItems[0].stripeCustomerId = this.user.stripe_customer_id;
          this.data.cartItems[0].stripeCardId = this.listCards[0].id;
        }
      } catch (e) {
        console.log(e);
      }
    },

    getCardImage(brand) {
      return stripe.getCardImage(brand);
    },

    chooseCard(card) {
      const index = this.currentCustomerView.index;
      if (card.card_type === 'stripe') {
        this.listCards[index] = {
          ...this.listCards[index],
          ...card
        };
        this.data.cartItems[index].stripeCustomerId = this.user.stripe_customer_id;
        this.data.cartItems[index].stripeCardId = card ? card.id : null;
        this.data.cartItems[index].paymentMethod = PAYMENT_METHOD.STRIPE;
      } else if (card.card_type === 'fluid') {
        this.data.cartItems[index].paymentMethod = PAYMENT_METHOD.FLUID;
      } else {
        this.data.cartItems[index].paymentMethod = null;
      }
      setTimeout(() => this.setOpenCreditCard(false), 400);
    },

    async confirmDateTime(data) {
      const index = this.currentDateTime.index;
      this.data.cartItems[index] = {
        ...this.data.cartItems[index],
        ...data
      };
      // await this.checkDeliveryTime(data.deliveryDate);
      this.deliveryDateNearest = data.deliveryDate;

      setTimeout(() => this.setOpenTime(false), 200);
    },

    chooseTime(index) {
      this.currentDateTime = {
        ...this.currentDateTime,
        index,
        data: {
          deliveryDate: this.data.cartItems[index].deliveryDate,
          deliveryTime: this.data.cartItems[index].deliveryTime
        }
      };

      this.setOpenTime(true);
    },

    openModalCreditCard(index) {
      this.currentCustomerView = {
        index,
        data: this.customerView[index]
      };
      this.setOpenCreditCard(true);
    },

    // formatDeliveryDate(date = null) {
    //   if (!date) return null;

    //   if (date === getTomorrow()) return 'Tomorrow';

    //   return dayjs(date).format('ddd, D MMM YYYY');
    // },

    grandSubtotal() {
      return parseFloat(
        this.groupedTenant.reduce((total, tenantItems) => {
          total += tenantItems
            .filter((v) => v.available || !v.available)
            .reduce((total, v) => total + v.total_price, 0);
          return total;
        }, 0)
      );
    },
    grandTax() {
      return parseFloat(
        this.groupedTenant.reduce((total, tenantItems) => {
          total += tenantItems
            .filter((v) => v.available || !v.available)
            .reduce((total, v) => total + v.tax, 0);
          return total;
        }, 0)
      );
    },
    grandTotal() {
      return this.grandSubtotal() + this.grandTax();
    },

    calculatingSubtotal(tenantItems) {
      const price = tenantItems.reduce((total, current) => total + current.total_price, 0);
      return displayPrice(price);
    },

    async getAddressDefault() {
      this.setSelectedDeliveryAddress(await this.$storage.getSelectedDeliveryAddress());
      // selectedDeliveryAddress
      if (this.list.length <= 1) {
        this.updateSelectedAddress(true, this.selectedDeliveryAddress);
        this.updateSelectedAddress(false, this.selectedBillingAddress);
      }
      // selectedBillingAddress
      if (this.isPreSelectedAddress) {
        this.setSelectedBillingAddress(this.list[0].id);
        this.updateSelectedAddress(false, this.list[0].id);
        this.setIsPickingDeliveryAndBilling(false, true);
      }
    },

    updateSelectedAddress(isDelivery, id) {
      const addresses = this.list;
      if (!id) {
        this.updateSelectedAddressToStorage(isDelivery, addresses[0].id);
      } else {
        const index = addresses.findIndex((item) => item.id === id);
        if (index === -1) {
          this.updateSelectedAddressToStorage(isDelivery, addresses[0].id);
        } else {
          this.updateSelectedAddressToStorage(isDelivery, id);
        }
      }
    },

    updateSelectedAddressToStorage(isDelivery, id) {
      if (isDelivery) {
        this.setSelectedDeliveryAddress(id);
        this.data.deliveryAddressId = id;
        this.$storage.setSelectedDeliveryAddress(id);
        if (this.list.length > 1) {
          this.setIsPickingDeliveryAndBilling(true, this.isPickingDeliveryAndBilling.isSelectBilling);
        }
      } else {
        this.setSelectedBillingAddress(id);
        this.data.billingAddressId = id;
        this.$storage.setSelectedBillingAddress(id);
        if (this.list.length > 1) {
          this.setIsPickingDeliveryAndBilling(this.isPickingDeliveryAndBilling.isSelectDelivery, true);
        }
      }
    },
    openModalAddress(type) {
      this.typeAddress = type;
      this.setOpenAddress(true);
    },

    updateInfor({ isDelivery, selectedAddressId }) {
      this.setOpenAddress(false);
      this.updateSelectedAddressToStorage(isDelivery, selectedAddressId);
    },
    async handleCheckCuisineCustomer() {
      const { data } = await apolloClient.query({
        query: CheckCustomerCuisines,
        variables: {
          customerId: this.selectedCompany.id
        }
      });
      let isShowModalCuisine = false;
      const cuisineCheck = data.checkCustomerCuisines;
      if (cuisineCheck.isSkip) {
        if (cuisineCheck.totalCountOrders === 1) {
          isShowModalCuisine = true;
        } else if (cuisineCheck.totalCountOrders >= 3 && cuisineCheck.totalCountOrders % 3 === 0) {
          isShowModalCuisine = true;
        }
      }
      if (isShowModalCuisine) {
        this.setOpen(false);
        this.setOpenModalCuisine(true);
      } else {
        await this[ACTIONS_CART.GET_CART_ITEMS]({
          userId: this.user.id,
          customerId: this.selectedCompany.id
        });
        this.$emit('place-order');
      }
    },
    async handleCloasingCuisine() {
      this.setOpenModalCuisine(false);
      await this[ACTIONS_CART.GET_CART_ITEMS]({
        userId: this.user.id,
        customerId: this.selectedCompany.id
      });
      this.$emit('place-order');
    },
    async placeOrder() {
      this.setOpen(true);
      try {
        const deliveryAddress = this.addressDefault(this.selectedDeliveryAddress);
        const tenantId = this.user.tenant.id;
        await this.groupedTenant.map((items, index) => {
          const cartItemIds = items.reduce(
            (ids, item) => (item.available || !item.available ? [...ids, item.id] : ids),
            []
          );
          this.data.cartItems[index].cartItemIds = cartItemIds;
        });
        const items = {
          ...this.data,
          deliveryAddress: deliveryAddress,
          tenantId: tenantId
        };
        Clevertap.onUserPlaceOrder({ cartList: this.cartList, items });
        const { data } = await apolloClient.mutate({
          mutation: saleCheckBlackListOrder,
          variables: items
        });
        this.setOpen(false);
        if (data.saleCheckBlackListOrder) {
          this.messageBlackList = data.saleCheckBlackListOrder;
          this.renderBlacklist++;
          this.setOpenModalBlackList(true);
        } else {
          await this.confirmOrder();
        }
      } catch (e) {
        console.log(e);
        this.setOpen(false);
        this.showAlertError();
      }
    },

    async confirmOrder() {
      this.setOpen(true);
      await this[ACTIONS_CUSTOMER.GET_CUSTOMER_DETAILS]({
        id: this.selectedCompany.id
      });
      if (this.customerDetails.data[0]?.persons.length == 0) {
        this.setOpen(false);
        setTimeout(async () => {
          await this.showAlert({
            message: this.$t('orderB2b.addPICMessage')
          });
        }, 500);
        return;
      } else {
        this.setOpen(false);
        await this.checkDuplicateOrderFunction();
      }
    },
    detectConnection() {
      const connection = navigator.onLine;
      if (connection) {
        this.isOnline = true;
      } else {
        this.isOnline = false;
      }
    },
    async showAlertError() {
      const alert = await alertController.create({
        header: 'Oops!',
        mode: 'ios',
        message: this.$t('something_went_wrong_please_try_again'),
        buttons: [
          {
            text: this.$t('OK'),
            handler: async () => {
              await this.detectConnection();
            }
          }
        ]
      });
      await alert.present();
    },
    async showAlert({ header, message }) {
      const alert = await alertController.create({
        header,
        message,
        buttons: [this.$t('close')]
      });
      setTimeout(async () => await alert.present(), 500);
    },
    async checkDuplicateOrderFunction() {
      await this.groupedTenant.map((items, index) => {
        const cartItemIds = items.reduce((ids, item) => (item.available ? [...ids, item.id] : ids), []);
        this.data.cartItems[index].cartItemIds = cartItemIds;
      });
      const itemsFind = [];
      for (let i in this.data.cartItems) {
        itemsFind.push({
          tenantId: this.data.cartItems[i].tenantId,
          deliveryDate: `${this.data.cartItems[i].deliveryDate}`
        });
      }
      this.getCompany = await this.$storage.getSelectedCompany();

      await this[ACTIONS_CART.CHECK_DUPLICATE_ORDER]({
        customerId: this.getCompany.id,
        deliveryAddressId: this.data.deliveryAddressId,
        items: itemsFind
      });

      if (this.status) {
        const isDuplicate = this.checkDuplicateOrder.length > 0;
        const existingOrderIsPaid = isDuplicate && this.checkDuplicateOrder[0]?.payment_type === 2;
        const existingOrderIsProcessing = isDuplicate && this.checkDuplicateOrder[0]?.order_status_id === 1;
        const newOrderIsPaid =
          this.data.cartItems[0].paymentMethod !== null && this.data.cartItems[0].paymentMethod !== undefined;

        if (isDuplicate && (existingOrderIsPaid || !existingOrderIsProcessing || newOrderIsPaid)) {
          // duplicate order popup
          this.setOpenCheckDuplicatieOrder(false);
          this.handleShowCardContentAlert(true);
          this.countModalCheckDuplicate = 0;
          setTimeout(() => {
            this.setOpenCheckDoubleOrder(true);
          }, 500);
        } else if (isDuplicate && !newOrderIsPaid && !existingOrderIsPaid && existingOrderIsProcessing) {
          // only unpaid order that can be merged
          // merge order popup
          this.setOpenCheckDoubleOrder(false);
          this.handleShowContentAlert(true);
          this.countModalCheckDuplicate = 0;
          setTimeout(() => {
            this.setOpenCheckDuplicatieOrder(true);
          }, 500);
        } else {
          this.setOpenOrderConfirm(true);
          this.titleOrderConfirm = this.$t('confirmation');
          this.subTitleOrderConfirm = this.$t('continue_order');
        }
      }
    },

    async openCheckDuplicatieOrder(params) {
      this.setOpenCheckDuplicatieOrder(false);
      if (params >= this.checkDuplicateOrder.length - 1) {
        this.data.cartItems[params].previousOrderId = this.checkDuplicateOrder[params]?.id;
        this.messageDuplicate = `${this.$t('order_merged')} #${this.checkDuplicateOrder[0]?.id}`;
        this.addOrder();
      } else {
        this.handleShowContentAlert(false);
        this.data.cartItems[params].previousOrderId = this.checkDuplicateOrder[params]?.id;
        setTimeout(() => {
          this.setOpenCheckDuplicatieOrder(true);
        }, 500);
      }
    },
    async skipCheckDuplicatieOrder(params) {
      this.setOpenCheckDuplicatieOrder(false);
      if (params >= this.checkDuplicateOrder.length - 1) {
        this.data.cartItems[params].previousOrderId = null;
        this.messageDuplicate = `${this.$t('new_order_placed')}`;
        this.addOrder();
      } else {
        this.handleShowContentAlert(false);
        setTimeout(() => {
          this.setOpenCheckDuplicatieOrder(true);
        }, 500);
      }
    },
    async proceesDoubleOrder(params) {
      this.setOpenCheckDoubleOrder(false);
      if (params >= this.checkDuplicateOrder.length - 1) {
        this.data.cartItems[params].previousOrderId = null;
        this.addOrder();
      } else {
        this.handleShowCardContentAlert(false);
        setTimeout(() => {
          this.setOpenCheckDoubleOrder(true);
        }, 500);
      }
    },
    handleShowContentAlert(firstAlert) {
      this.reRender++;
      this.titleCheckDuplicatieOrder = this.$t('title_check_duplicate');
      this.countModalCheckDuplicate++;
      this.subTitleCheckDuplicatieOrder = `<strong>${
        this.selectedCompany?.name ? this.selectedCompany?.name : this.selectedCompany?.branch
      }</strong> ${this.$t('already_has')}<br/>${this.$t('an_order_with')} <strong>${
        this.checkDuplicateOrder[firstAlert ? 0 : this.countModalCheckDuplicate].tenant_name
      }</strong> ${this.$t('for_delivery_on')}<br/>${this.checkDuplicateOrder[
        firstAlert ? 0 : this.countModalCheckDuplicate
      ].delivery_date.slice(0, 10)} ${
        this.checkDuplicateOrder[firstAlert ? 0 : this.countModalCheckDuplicate].delivery_time
      }.<br/>
      <br/>
      ${this.$t('do_you_want_merge')}`;
    },
    handleShowCardContentAlert(firstAlert) {
      this.reRender++;
      this.titleCheckDuplicatieOrder = this.$t('duplicate_order');
      this.countModalCheckDuplicate++;
      this.subTitleCheckDuplicatieOrder = `<strong>${
        this.selectedCompany?.name ? this.selectedCompany?.name : this.selectedCompany?.branch
      }</strong> ${this.$t('already_has')}<br/>${this.$t('an_order_with')} <strong>${
        this.checkDuplicateOrder[firstAlert ? 0 : this.countModalCheckDuplicate].tenant_name
      }</strong> ${this.$t('for_delivery_on')}<br/>${this.checkDuplicateOrder[
        firstAlert ? 0 : this.countModalCheckDuplicate
      ].delivery_date.slice(0, 10)} ${
        this.checkDuplicateOrder[firstAlert ? 0 : this.countModalCheckDuplicate].delivery_time
      }.<br/>
      <br/>
      ${this.$t('do_you_want_proceed')}`;
    },

    async addOrder() {
      this.setOpenOrderConfirm(false);
      this.setOpen(true);
      this.groupedTenant.map((items, index) => {
        const cartItemIds = items.reduce(
          (ids, item) => (item.available || !item.available ? [...ids, item.id] : ids),
          []
        );
        this.data.cartItems[index].cartItemIds = cartItemIds;
        this.data.delivery_instruction =
          this.deliveryInstruction !== '' ||
          (this.savedDeliveryInstruction &&
            this.deliveryInstruction !== this.customerView[0].delivery_instruction)
            ? this.deliveryInstruction
            : this.customerView[0].delivery_instruction;
      });
      await this[ACTIONS_ORDER.ADD_ORDER](this.data);
      this.setOpen(false);
      await this[ACTIONS_CART.FINISH_ORDER](true);
      await this.OrderNotice();
    },
    async OrderNotice() {
      if (this.message) {
        if (this.message === 'Cart item not found!') {
          const alert = await alertController.create({
            header: this.$t('errorTitle'),
            message: this.message,
            buttons: [
              {
                text: this.$t('OK'),
                handler: () => {}
              }
            ]
          });
          this.$emit('refresh-cart');
          alert.present();
        } else {
          await this.showAlert({
            header: this.$t('order_failed'),
            message: this.message
          });
          this.$emit('refresh-cart');
        }
        this.$emit('place-order');
      } else {
        await this.showAlert({
          header: this.$t('success'),
          message: this.messageDuplicate.length > 0 ? this.messageDuplicate : this.$t('order_added')
        });
        await this.handleCheckCuisineCustomer();
      }
    },
    getDeliveryInstruction(deliveryInstructionVal) {
      this.deliveryInstruction = deliveryInstructionVal;
      this.savedDeliveryInstruction = true;
    }
  }
});
</script>
<style lang="scss" scoped>
ion-item::part(native) {
  padding: 0;
}

.payment-type {
  border: 1px solid silver;
  border-radius: 6px;
}

.credit-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 14px;
}

.due-date {
  display: flex;
  color: #fb0101;
  font-weight: bold;
  padding: 5px 42px;
}

.image-card {
  width: 40px;
}

.adjust-top {
  margin-top: -6px;
}

.place-order {
  text-transform: capitalize;
}
</style>

<style lang="scss">
.modal-confirm {
  --width: calc(100% - 3rem);
  --height: 35%;

  &::part(content) {
    border-radius: 12px;
  }
}

#row {
  height: 50px;
}
.box-label {
  background-color: var(--ion-color-text-error-100);
  width: fit-content;
  padding: 3px 7px;
  border-radius: 7px;
}
</style>
