<template>
  <ion-page>
    <!-- header -->
    <ion-header>
      <ion-toolbar>
        <ion-grid>
          <ion-row class="align-center" id="row">
            <ion-col size="9" class="d-flex">
              <ion-buttons class="mr-2" @click="$emit('close-modal')">
                <ion-icon size="small" :icon="arrowBackOutline"></ion-icon>
              </ion-buttons>
              <ion-label class="fs-4 fw-bold">{{
                isDelivery ? $t('delivery_address') : $t('billing_address')
              }}</ion-label>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <div class="content w-100">
        <ion-card class="card" v-for="(address, index) in list" :key="index + uniqueId()">
          <ion-card-content class="pa-0">
            <div class="pa-3">
              <div>
                <ion-label v-if="address.is_default" class="is-default">{{
                  $t('default_billing_address')
                }}</ion-label>
              </div>

              <div
                class="d-flex mt-2 align-center justify-space-between"
                @click="onSelectAddress(address.id)"
              >
                <div>
                  <ion-label color="dark">{{ getAddressFormat(address) }}</ion-label>
                </div>
                <div>
                  <input
                    class="radio ml-2"
                    type="radio"
                    name="selectedAddressId"
                    v-model="selectedAddressId"
                    :value="address.id"
                  />
                </div>
              </div>
            </div>
            <div
              class="w-100 border-top d-flex py-2 align-center justify-center"
              @click="changeAddress(address)"
            >
              <ion-label color="grey" class="text-uppercase fw-500">{{ $t('change_the_address') }}</ion-label>
            </div>
          </ion-card-content>
        </ion-card>
      </div>
      <ion-modal
        :is-open="isOpenAddAddressRef"
        css-class="modal-address"
        @didDismiss="setOpenAddAddress(false)"
      >
        <modal-add-address
          :currentAddress="currentAddress"
          :isAddNew="currentAddress === null"
          :type="isDelivery ? TYPE_ADDRESS.DELIVERY : TYPE_ADDRESS.BILLING"
          @close-modal="setOpenAddAddress(false)"
        ></modal-add-address>
      </ion-modal>
    </ion-content>

    <ion-footer>
      <ion-toolbar>
        <div class="pa-3">
          <ion-button @click="addNewAddress" expand="block" fill="outline">
            <ion-icon slot="start" :icon="addOutline"></ion-icon>
            {{ $t('add_new_address') }}
          </ion-button>
          <div class="mt-3">
            <ion-button @click="updateInfor" :disabled="list?.length < 1" expand="block"
              >{{ $t('update_information') }}
            </ion-button>
          </div>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>
<script>
import { ref, defineComponent } from 'vue';
import { getAddressFormat } from '@/modules/sale/services/libs/helper';
import { arrowBackOutline, businessOutline, addOutline } from 'ionicons/icons';

import { ACTIONS as ACTIONS_ADDRESS } from '@/modules/sale/store/address/actions';
import { createNamespacedHelpers } from 'vuex';
import ModalAddAddress from './ModalAddAddress/Index.vue';
import { TYPE_ADDRESS } from '@/modules/sale/constants';
import { randomId } from '@/modules/sale/services/libs/helper';

const { mapGetters: mapGettersAddress, mapActions: mapActionsAddress } = createNamespacedHelpers(
  'sale/address'
);
export default defineComponent({
  props: {
    isDelivery: {
      type: Boolean,
      required: true
    },
    selectedDeliveryAddress: {
      type: Number,
      required: true
    },
    selectedBillingAddress: {
      type: Number,
      required: true
    }
  },

  components: {
    // components
    ModalAddAddress
  },

  emits: ['close-modal', 'update-infor'],

  setup() {
    const user = ref(null);
    const selectedCompany = ref(null);
    const selectedAddressId = ref(null);
    const currentAddress = ref(null);
    const uniqueId = () => randomId();

    return {
      user,
      selectedCompany,
      getAddressFormat,
      selectedAddressId,
      currentAddress,
      TYPE_ADDRESS,
      uniqueId,

      // icons
      arrowBackOutline,
      businessOutline,
      addOutline
    };
  },

  inject: ['$storage'],
  data() {
    return {
      isOpenAddAddressRef: false
    };
  },
  async mounted() {
    this.user = await this.$storage.getUser();
    this.selectedCompany = await this.$storage.getSelectedCompany();
    this.getAddresDefault();
  },

  computed: {
    ...mapGettersAddress(['list'])
  },

  methods: {
    ...mapActionsAddress([ACTIONS_ADDRESS.GET_ADDRESSES]),
    setOpenAddAddress(value) {
      this.getAddresDefault();
      this.isOpenAddAddressRef = value;
    },
    changeAddress(address) {
      this.currentAddress = address;
      this.setOpenAddAddress(true);
    },
    getAddresDefault() {
      const searchAddressId = this.list.find((items) => items.is_default === true);
      if (searchAddressId) {
        this.selectedAddressId = searchAddressId.id;
      } else {
        this.selectedAddressId = this.isDelivery ? this.selectedDeliveryAddress : this.selectedBillingAddress;
      }
    },
    onSelectAddress(id) {
      this.selectedAddressId = id;
    },
    addNewAddress() {
      this.currentAddress = null;
      this.setOpenAddAddress(true);
    },
    updateInfor() {
      this.$emit('update-infor', {
        isDelivery: this.isDelivery,
        selectedAddressId: this.selectedAddressId
      });
    }
  }
});
</script>
<style lang="scss" scoped>
.content {
  padding: 1rem;
}

.card {
  margin: 1rem 0;
}

.icon-business {
  font-size: 20px;
}

.radio {
  color: val(--ion-color-primary);
}

.is-default {
  color: #a2aecb;
}
#row {
  height: 50px;
}
</style>
